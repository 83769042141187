<template>
  <div class="loginPage_wrapper PublicHearing_background">
    <el-card class="loginPage_box ">
      <div class="project_logo" style="display: none">
        <img src="./resources/gerbNN.svg" alt="" width="64" height="64" style="flex: none;">
        <div class="project_title">
          Министерство градостроительной деятельности и развития агломераций Нижегородской обл.
        </div>
      </div>
     <slot name="loginForm"></slot>
    </el-card>
  </div>
</template>

<script>
export default {
  name: 'login-publicHearing'
}
</script>

<style lang="scss" scoped>
.PublicHearing_background {
  background: url(./resources/background.jpg) no-repeat;
}
</style>
